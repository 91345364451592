//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "validarCredencialesModal",
  props: ["model"],
  data() {
    return {
      usuario: "",
      password: "",
    }
  },
  methods: {
    onClose() {
      this.model = false;
      this.$emit("on-close");
    },
    onShow() {
      this.usuario = "";
      this.password = "";
      this.$nextTick(() => this.$refs.first.focus());
    },
    aplicarCredenciales() {
      let credenciales = {usuario: this.usuario, password: this.password};
      this.$emit("validar", credenciales)
    }
  }
}
